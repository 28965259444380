import React from 'react'
import ArrowRight from '../images/icons/arrow-right.svg'
import Layout from '../components/layout'
import '../css/transformation.scss'
import Nav from '../components/nav/nav'
import Insight from './home/insight/insight'
import TransformationLetter from '../images/icons/transformation.svg'
import { Link } from 'gatsby'

const Transformation = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="transformation pt-5 position-relative">
                <div className="position-relative">
                    {/* hero section */}
                    <div className="bg-hero py-5">
                        <div className="container py-3 my-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #040C22' }}></div>
                                        </div>
                                        <div className="col-md-8">
                                            <h1 className="font-weight-bold" style={{ lineHeight: '58px', color: '#040C22', fontSize: '2.8rem' }}>Transformation</h1>

                                            <p className="mb-0" style={{ lineHeight: '28px', fontSize: '1.3rem', color: '#040C22' }}>Increase your business’ chance of survival and achieve long-term success with all round transformation.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">
                                    <p className="small mb-0" style={{ color: '#040C22', lineHeight: '26px', fontSize: '1.06rem' }}>Let us work with you to transform your business — be it operations, people or systems, — to better position your business to thrive, and equip you to pursue new innovation-driven opportunities as they emerge.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="transformation-letter-div mb-5">
                        <img src={TransformationLetter} alt="" className="transformation-img" />
                    </div>
                </div>


                <div className="my-5 py-5"></div>
                <div className="my-md-5 py-md-5"></div>


                <div className="container my-5 py-5">
                    <div className="bg-optimize py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Optimize your business trajectory</h4>

                                                <p className="text-secondary" style={{ lineHeight: '26px' }}>We will steer your business in the right path, aligning your business strategy and vision, to ensure you remain relevant in today’s fast changing landscape and survive in the evolving business environment.</p>

                                                <p className="text-secondary mb-1" style={{ lineHeight: '26px' }}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Build and sustain digital culture across people and processes.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Deliver extraordinary services.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Be a top contender in the market through optimization.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Align people, process and technologies with your business vision.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Sustain high performance across value chains.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Coordinate transformation across departments and teams.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'transformation' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/transformation-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="bg-performance py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Performance transformation through Technology</h4>

                                                <p className="text-secondary" style={{ lineHeight: '26px' }}>We will harness the power of transformative technology to remodel legacy systems; optimizing and automating operations and processes to foster digital growth and create better digital experiences for staff and customers alike.</p>

                                                <p className="text-secondary mb-1" style={{ lineHeight: '26px' }}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Win the market with the power of digital technology.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Reshape teams, tools and timelines for best results.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Match specific team capabilities with technology.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Boost team and tools performance with new technologies.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Adopt a holistic approach to technological change.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Gain competitive advantage with improved customer experience.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'transformation' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/transformation-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-5 py-5">
                    <div className="bg-design-thinking py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Adopt a design thinking approach</h4>

                                                <p className="text-secondary" style={{ lineHeight: '26px' }}>As true believers in the agile process, we will work with you to transform your business’s problem-solving methodology, adopting a more creative and critical approach that guarantees big results.</p>

                                                <p className="text-secondary mb-1" style={{ lineHeight: '26px' }}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Develop a more iterative and rapid way of doing things.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Organize ideas and Improve decision making processes.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Unlearn and relearn faster for improved resolution timelines.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Approach problems with an agile mindset.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Foster a culture of innovative thinking.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{ lineHeight: '26px' }}>Sustain competitive capabilities to stay ahead of the market.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'transformation' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/transformation-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="my-md-5 my-0 py-md-5 py-0"></div>
            <Insight />

        </Layout>
    )
}

export default Transformation
